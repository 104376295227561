(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("echarts"), require("React"), require("PropTypes"));
	else if(typeof define === 'function' && define.amd)
		define("EchartsReact", ["echarts", "React", "PropTypes"], factory);
	else if(typeof exports === 'object')
		exports["EchartsReact"] = factory(require("echarts"), require("React"), require("PropTypes"));
	else
		root["EchartsReact"] = factory(root["echarts"], root["React"], root["PropTypes"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 